/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"www.fit-okna.cz"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1s9v8ag css-42e4bw --parallax pb--60 pt--60" name={"e9l42pm7pk"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/40398/5d184116904a401fb3ec154f084afd95_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/40398/5d184116904a401fb3ec154f084afd95_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/40398/5d184116904a401fb3ec154f084afd95_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/40398/5d184116904a401fb3ec154f084afd95_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/40398/5d184116904a401fb3ec154f084afd95_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/40398/5d184116904a401fb3ec154f084afd95_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/40398/5d184116904a401fb3ec154f084afd95_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/40398/5d184116904a401fb3ec154f084afd95_s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Proč děláme značku SAPELI ?"}>
              </Title>

              <Text className="text-box text-box--center fs--26" style={{"maxWidth":""}} content={"<span style=\"font-style: italic; color: var(--color-blend--15); font-weight: bold;\">Firma SAPELI a.s. <br>je na trhu již od roku 1994 a v průběhu velice krátké doby se stala největším výrobcem dveří v ČR. <br>Je to rodinná česká firma, která pro svoje dveře vybírá materiály od českých dodavatelů. <br>Kvalita dveří je prověřena nejen dlouhodobou instalací, ale také na zkušebnách. <br>U některých modelů dveří lze za určitých provozních podmínek získat až 5-ti letou záruku. <br>Ze dveří se do interiéru neuvolňují žádné škodlivé látky a při výrobě jsou používány ekologické materiály.</span>"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--center" content={"Zaměření a vypracování nabídky zdarma&nbsp;"}>
              </Subtitle>

              <Text className="text-box text-box--center fs--30" style={{"maxWidth":""}} content={"<span style=\"font-style: italic;\"><span style=\"color: var(--black);\">Jako u ostatních našich služeb je první návštěva našeho technika na&nbsp;</span><span style=\"color: rgb(0, 0, 0); font-family: &quot;Patrick Hand&quot;, sans-serif; font-size: 30px; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 500; letter-spacing: 0.3px;\">úvodní konzultaci a</span><span style=\"color: var(--black);\"><br>&nbsp;základní zaměření zdarma&nbsp;</span></span>"}>
              </Text>

              <Button className="btn-box" content={"Tlačítko"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}